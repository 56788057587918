@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.profile_container {
  margin-bottom: 48px;
  @include laptop {
    margin-bottom: 32px;
  }
  @include tablet {
    margin-bottom: 24px;
  }
}

.profile_form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.user_verification_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $white1;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  padding: 16px;
  font-weight: 600;
  background:
    linear-gradient(0deg, $white2, $white2),
    linear-gradient(0deg, $white1, $white1);
    &:active, &:hover, &:focus {
      border: 1px solid $white1;
      outline: 0;
      outline-offset: 0;
    }

    p {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }
}