@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.structure_container {
  margin-bottom: 48px;
  @include laptop {
    margin-bottom: 32px;
  }
  @include tablet {
    margin-bottom: 24px;
  }
  .structure_top_controls_second {
    height: 48px;
    margin-bottom: 24px;
  }
}

.structure_top {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  margin-bottom: 24px;
  @include tablet {
    gap: 12px;
    flex-direction: column;
  }
  .structure_top_title {
    margin-bottom: 0;
  }

}

.structure_top_controls {
  display: flex;
  gap: 12px;
  align-items: center;
  @include tablet {
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }

  .structure_top_controls_first {
    text-wrap: nowrap;
    white-space: nowrap;
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0 32px;
    background: $white;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    @include tablet {
      width: 100%;
    }
  }
}

.structure_container_white {
  padding: 8px;
  @include scrollbar($white2, $white2, $white2);
  overflow-x: scroll;
}

.structure_table {
  thead {
    th {
      padding: 4px;

      &:nth-child(2) {
        text-align: left;
      }
    }
  }
  tbody {
    td {
      padding: 4px;
      @include tablet {
        padding: 12px 4px;
      }
    }
  }
}

.structure_table_control_icon {
  width: 24px;
}

.structure_table_avatar_name {
  display: flex;
  gap: 12px;
  text-align: left;
  .structure_table_avatar {
    width: 40px;
    height: 40px;
    border-radius: 12px;
  }

  .structure_table_name_id {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .structure_table_name {
      text-wrap: nowrap;
      color: $blueDark;
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
    }

    .structure_table_id {
      text-wrap: nowrap;
      color: $blueLight;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      display: flex;
      gap: 4px;
    }
  }
}

.structure_table_number_sold {
  padding: 6px 12px;
  background: $violetLight;
  border-radius: 6px;
  width: fit-content;
  color: $white;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  margin: 0 auto;
  @include tablet {
    margin-right: 0;
  }
}

.structure_table_email {
  gap: 12px;
  .structure_table_email_text {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;

  }
}

.structure_table_number_active {
  background: $violetLight1;
}

.structure_table_turnover {
  text-wrap: nowrap;
}

.structure_container_white_bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  padding: 24px;
  @include laptop {
    padding: 12px;
  }
  @include tablet {
    justify-content: center;
  }
}

.structure_container_showing {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: $blueLight;
}

.structure_table_subrow {
  display: flex;
  flex-direction: column;
  gap: 12px;
  @include tablet {
    gap: 8px;
  }
  .structure_table_subrow_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    .structure_table_subrow_item_first {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: $blueLight;
      @include tablet {
        font-size: 12px;
        line-height: 18px;
      }
    }
    .structure_table_subrow_item_second {
      font-size: 14px;
      line-height: 20px;
      font-weight: 700;
      text-align: right;
      color: $blueDark;
      @include tablet {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}

.numberOfTotalAgentsContainer {
  background: $gradientOrangeColor;
  color: $white;
  font-weight: 500;
  width: fit-content;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;
}